<template>
  <div
    class="form-no-messages"
    style="position:relative"
  >
    <v-container>
      <div
        v-if="$store.getters.getMobileModel !== 'PDA'"
        class="qrcode-wrap"
        :style="{display: showQrScaner}"
      >
        <QrCodeScanComponent @scan-result="onPCodeScan" />
      </div>
      <v-row dense>
        <v-col
          :cols="$store.getters.getMobileModel !== 'PDA' ? 11 : 12"
        >
          <v-text-field
            id="input-pcode"
            v-model="qrPcodeKey"
            autocomplete="off"
            label="품번"
            placeholder="QR 스캔"
            @keyup.enter.prevent="onEnterPcode"
          />
        </v-col>
        <v-col
          v-if="$store.getters.getMobileModel !== 'PDA'"
          class="pb-0 pt-4 pl-0"
          cols="1"
        >
          <v-icon
            @click="showQrScaner = showQrScaner === 'none' ? 'block' : 'none'"
          >
            mdi-camera
          </v-icon>
        </v-col>
      </v-row>
      <v-row
        v-if="receiptInfo.품명"
        dense
      >
        <v-col
          cols="12"
        >
          <v-text-field
            v-model="receiptInfo.품명"
            label="품명"
            autocomplete="off"
            :readonly="true"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="receiptInfo.장소위치코드"
        dense
      >
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.장소위치코드"
            autocomplete="off"
            label="기존위치코드"
            :readonly="true"
            filled
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.위치명"
            label="기존위치명"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="6"
        >
          <v-text-field
            id="input-location"
            v-model="qrLocation"
            label="변경위치코드"
            placeholder="QR 스캔"
            autocomplete="off"
            @keyup.enter.prevent="onEnterLoation"
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-if="locationInfo"
            v-model="locationInfo"
            autocomplete="off"
            :readonly="true"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.발주번호"
            label="발주번호"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.거래처명"
            label="거래처명"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="6"
        >
          <v-text-field
            id="input-qrkey"
            v-model="qrKey"
            label="일련번호"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
        <v-col
          cols="6"
        >
          <v-text-field
            v-model="receiptInfo.현재수량"
            label="현재수량"
            autocomplete="off"
            :readonly="true"
            filled
          />
        </v-col>
      </v-row>

      <loading
        :active.sync="isLoading"
        color="rgb(2, 110, 156)"
        loader="bars"
      />
    </v-container>
  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import AppLib from '../../share/AppLib'
import ConstDef from '../../../../MesSmartVue/src/share/ConstDef'
import HttpService from '../../share/service/HttpService'
import QrCodeScanComponent from '../../components/QrCodeScanComponent'

export default {
  name: 'PurchaseDelivery',
  components: {
    Loading,
    QrCodeScanComponent
  },
  data: function () {
    return {
      isLoading: false,
      userInfo: null,
      qrPcodeKey: null,
      qrKey: ' ',
      qrLocation: null,
      receiptInfo: { 품명: null, 현재수량: ' ', 발주번호: ' ', 거래처명: ' ' },
      locationInfo: null,
      pCodeScan: 0,
      // qrcode scan
      showQrScaner: 'none'
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
    HttpService.reqPost(null, '/isLogin')
  },
  mounted () {
    this.initInput()
  },
  updated () {
  },
  methods: {
    initInput () {
      this.qrPcodeKey = null
      this.qrLocation = null
      this.pCodeScan = 0
      this.$Q('#input-pcode').focus()
    },
    onEnterLoation (e) {
      if (this.qrLocation === null) return
      if (this.qrPcodeKey === null || this.qrPcodeKey.length === 0) {
        this.$snotify.error('품목 정보를 스캔하여 주세요')
        this.initInput()
        return
      }
      this.showQrScaner = 'none'
      this.isLoading = true
      this.$_sp.runNoEncodeFindSqlProc('장소위치정보', { 장소위치코드: this.qrLocation })
        .then((data) => {
          const locationInfo = this.$_sp.MakeModel(data)
          if (locationInfo.length === 0) {
            this.isLoading = false
            this.$snotify.error(`${this.qrLocation} 코드의 위치 정보를 검색 할 수 없습니다.`)
            this.initInput()
          } else {
            this.locationInfo = locationInfo[0].위치명
            this.updateLocation(this.qrPcodeKey, this.qrLocation, this.qrKey, this.$_.findWhere(ConstDef.PURCHASE_INOUT_CODE, { 코드명: '자재입고' }).코드)
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onEnterPcode (e) {
      let split = null
      try {
        split = AppLib.base64DecodeSubstring(this.qrPcodeKey).split('/')
      } catch {
        this.$snotify.error('제품정보를 확인할 수 없습니다.')
        this.initInput()
        return
      }
      this.qrKey = parseInt(split[1])
      this.qrPcodeKey = split[0]
      this.$_sp.runNoEncodeFindProc('spFindAllReceiptBypCodeAndKeyAndInput', { 품번: this.qrPcodeKey, 입고키: this.qrKey, 입출고구분: this.$_.findWhere(ConstDef.PURCHASE_INOUT_CODE, { 코드명: '자재입고' }).코드 })
        .then((data) => {
          const receiptInfo = this.$_sp.MakeModel(data)
          if (receiptInfo.length !== 1) {
            this.$snotify.error('해당 품목의 입고 정보가 잘못 되었습니다.')
            this.initInput()
          } else {
            this.receiptInfo = receiptInfo[0]
            this.$Q('#input-location').focus()
          }
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    },
    onPCodeScan (result) {
      if (this.pCodeScan === 0) {
        this.qrPcodeKey = result
        this.pCodeScan = 1
        this.onEnterPcode(null)
      } else {
        this.qrLocation = result
        this.onEnterLoation(null)
      }
    },
    updateLocation (pCode, location, key, inOutType) {
      const param = {
        품번: pCode,
        입고키: key,
        입출고구분: inOutType,
        장소위치코드: location
      }
      this.$_sp.runUpdateSqlProc('창고수불', ['품번', '입고키', '입출고구분'], null, [param])
        .then((data) => {
          this.isLoading = false
          this.$snotify.info('저장 되었습니다.')
          setTimeout(() => {
            this.initInput()
          }, 10)
        })
        .catch(error => {
          this.isLoading = false
          this.initInput()
          if (error.response.status === ConstDef.GENERRAL_ERROR) {
            this.$snotify.error(error.response.data.message)
          } else {
            this.$snotify.error(`에러코드 : ${error.response.status}`)
          }
        })
    }
  }
}
</script>

<style lang="scss">

</style>
